import { useRef, useState } from 'react'
import { BizTemplatePosterType, GreetPosterType } from '../state/boostify/boostify.types'
import { useRecoilValue } from 'recoil'
import { boostifyTemplateDataState } from '../state/boostify/boostify.atom'
import { boostifyFreeDefaultData } from '../state/boostify/boostify.defaults'
import { Backdrop, Box, Card, CardActions, CardContent, CardMedia, CircularProgress, Dialog, IconButton, Skeleton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import html2canvas from 'html2canvas'
import { isLoggedInState } from '../state/user/user.selector'
import BizImageTemplates from './BizImageTemplates'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { userState } from '../state/user/user.atom'
import { dmLicenseState, todayDownloadIDState } from '../state/license/license.atom'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../utils/firebase/firebase'

type actionType = 'download' | 'share' | 'copy'

const bizImgAction = async (action: actionType = 'download', bizImage: string, name: string) => {
    if (bizImage) {
        switch (action) {
            case 'download': {
                const link = document.createElement('a');
                link.href = bizImage;
                link.download = `${name || 'Boostify-Biz'}.png`;
                document.body.appendChild(link)
                link.click();
                document.body.removeChild(link)
                return;
            }
            case 'share': {
                if (navigator.canShare) {
                    // const title = 'My Image With Text';
                    // const blobObj = await fetch(bizImage).then(res => res.blob())
                    // const file = new File([blobObj], 'my-image-with-text.png', { type: 'image/png', lastModified: new Date() });
                    const blob = await fetch(bizImage).then((res) => res.arrayBuffer())
                        .then((buffer) => new Blob([buffer], { type: 'image/png' }));
                    const imgFile = new File([blob], 'my-image-with-text.png', { type: 'image/png', lastModified: Number(new Date()) });
                    const files = [imgFile];
                    if (navigator.canShare({ files })) {
                        return navigator.share({
                            // title: title,
                            files,
                            // text: name,
                        });
                    }
                    return;
                }
                break;
            }
            case 'copy': {
                try {
                    const blob = await fetch(bizImage).then((res) => res.arrayBuffer())
                        .then((buffer) => new Blob([buffer], { type: 'image/png' }));
                    const imgFile = new File([blob], 'my-image-with-text.png', { type: 'image/png', lastModified: Number(new Date()) });
                    try {
                        navigator.clipboard.write([
                            new ClipboardItem({ 'image/png': imgFile })
                        ])
                            .then(() => {
                                alert('Image Copied to Clipboard')
                                // notify("info", "Copied", { hideProgressBar: true, pauseOnHover: false, autoClose: 500 })
                            })
                            .catch(err => {
                                throw (err)
                            })
                    } catch (error) {
                        console.error('navigator error: ', error);
                    }
                    return;
                } catch (error) {
                    console.error({ error });
                    // return notify("error", "Unable To Copy QR")
                }
            }
        }
    }
}

function GenPoster({
    bizImage,
    setBizImage,
    event,
    rawImg,
    imageid
}: {
    bizImage: string | null,
    setBizImage: React.Dispatch<React.SetStateAction<string | null>>,
    event: GreetPosterType | BizTemplatePosterType,
    rawImg: string,
    imageid: string
}) {
    const [tname, setTname] = useState<string>('template8')

    const [genImage, setGenImg] = useState<boolean>(true)

    const bizRef = useRef<HTMLDivElement>(null)

    const templateData = useRecoilValue(boostifyTemplateDataState)
    const { defaultpid } = useRecoilValue(userState)
    const { name } = useRecoilValue(dmLicenseState)
    const todaydownlaodid = useRecoilValue(todayDownloadIDState)
    const isLoggedIn = useRecoilValue(isLoggedInState)

    const onLoadImage = async () => {
        await html2canvas(bizRef.current as HTMLDivElement, { width: 1080, height: 1080 })
            .then(canvas => {
                setBizImage(canvas.toDataURL())
                setGenImg(false)
            })
            .catch(err => {
                console.error('Error on Load Raw IMG', err);
                setGenImg(false)
            })
    }

    const handleAction = async (action: ('download' | 'share' | 'copy') = 'download') => {
        if (name && (name === 'Starter')) {
            if (todaydownlaodid && (todaydownlaodid !== imageid)) return alert('You can download only one image per Day.')
            if (!todaydownlaodid) {
                const today = Number(new Date(new Date().toDateString())).toString(36)
                const docRef = doc(db, 'dm', 'posters', 'starterdownload', defaultpid)
                await setDoc(docRef, { today, id: imageid })
            }
        }
        return await bizImgAction(action, bizImage as string, event.title)
    }

    const onChangeTemplate = (_tname: string) => {
        if (_tname === tname) return;
        setTname(_tname)
        setGenImg(true)
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={genImage}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CardContent>
                <div className='template-container' style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '10px' }}>
                        {
                            [
                                'template8',
                                'template2',
                                'template6',
                                'template3',
                                'template7',
                                'template5',
                                'template4',
                                'template1',
                            ].map((template, index) => (
                                <div
                                    key={template}
                                    onClick={() => onChangeTemplate(template)}
                                    style={{
                                        flex: '0 0 auto',
                                        border: tname === template ? '1px solid black' : '1px dashed gray',
                                        padding: '5px'
                                    }}
                                >
                                    Template {index + 1}
                                </div>
                            ))
                        }
                        {/* <div onClick={() => onChangeTemplate('template1')} style={{ flex: '0 0 auto', border: (tname === 'template1') ? '1px solid black' : '1px dashed gray', padding: '5px' }}>Template 1</div>
                        <div onClick={() => onChangeTemplate('template2')} style={{ flex: '0 0 auto', border: (tname === 'template2') ? '1px solid black' : '1px dashed gray', padding: '5px' }}>Template 2</div>
                        <div onClick={() => onChangeTemplate('template3')} style={{ flex: '0 0 auto', border: (tname === 'template3') ? '1px solid black' : '1px dashed gray', padding: '5px' }}>Template 3</div>
                        <div onClick={() => onChangeTemplate('template4')} style={{ flex: '0 0 auto', border: (tname === 'template4') ? '1px solid black' : '1px dashed gray', padding: '5px' }}>Template 4</div>
                        <div onClick={() => onChangeTemplate('template5')} style={{ flex: '0 0 auto', border: (tname === 'template5') ? '1px solid black' : '1px dashed gray', padding: '5px' }}>Template 5</div> */}
                    </div>
                </div>
            </CardContent>
            <CardActions>
                <IconButton onClick={() => handleAction('download')}>
                    <DownloadIcon />
                </IconButton>
                <IconButton onClick={() => handleAction('share')}>
                    <ShareIcon />
                </IconButton>
                <IconButton onClick={() => handleAction('copy')}>
                    <ContentCopyIcon />
                </IconButton>
            </CardActions>
            {
                genImage ?
                    <BizImageTemplates
                        tname={tname}
                        bizRef={bizRef}
                        onLoadRawImg={onLoadImage}
                        rawImg={rawImg}
                        templateData={isLoggedIn ? templateData : boostifyFreeDefaultData}
                    />
                    :
                    ''
            }
        </>
    )
}

export default function BizPoster({
    open, setOpen, rawImg, imageid, event
}: {
    open: boolean, setOpen: Function, rawImg: string, imageid: string, event: GreetPosterType | BizTemplatePosterType
}) {

    const [bizImage, setBizImage] = useState<string | null>(null)

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        // maxWidth='lg'
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{ width: { sm: '50%' } }}
                >
                    <CardContent>
                        {event.title}
                    </CardContent>
                    {
                        (!bizImage) ?
                            <Skeleton variant="rectangular" width="100%" height={"200px"} />
                            :
                            <>
                                <CardMedia
                                    component="img"
                                    image={bizImage}
                                    loading="lazy"
                                />
                            </>
                    }
                    <GenPoster
                        bizImage={bizImage}
                        setBizImage={setBizImage}
                        event={event}
                        rawImg={rawImg}
                        imageid={imageid}
                    />
                </Card>
            </Box>
        </Dialog>
    )
}
